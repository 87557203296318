import Vue from "vue";

export default {

    recuperarInscricaoEspecificaUsuarioLogado(processoOid){
        return Vue.axios.get("inscricao", {
            params: {
                processoOid
            }
        });
    },

    salvarInscricaoUsuarioLogado(processoOid, arquivos, inscricao) {

        let formData = new FormData();

        arquivos.forEach(file => formData.append("files", file));

        formData.append("inscricao", new Blob([JSON.stringify(inscricao)], {type: "application/json"}));

        return Vue.axios.post("inscricao/salvar/" + processoOid, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    cancelarInscricaoUsuarioLogado(processoOid) {
        return Vue.axios.put("inscricao/cancelar", {
            processoOid
        });
    },

    recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
        return Vue.axios.post("inscricao/filtro", {
            params: {
                processoOid: paramsFiltro.processoOid,
                opcaoOid: paramsFiltro.opcaoOid,
                nome: paramsFiltro.nome,
                cpf: paramsFiltro.cpf,
                situacoesInscricoesOid: paramsFiltro.situacoesInscricaoOid
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    },

    alterarSituacaoInscricao(inscricaoOid, novaSituacaoInscricaoOid, justificativaOid) {
        return Vue.axios.put("inscricao/situacaoInscricao", {
            inscricaoOid,
            novaSituacaoInscricaoOid,
            justificativaOid
        });
    },

    editarNecessidadeEspecial() {

    },

    recuperarQtdInscricoesPorProcessosAbertosEEmAndamento() {
        return Vue.axios.get("inscricao/dashboard/qtdInscricoes");
    },

    recuperarNotasPorInscricao(inscricaoOid){
        return Vue.axios.get('inscricao/recuperarNotas/'+ inscricaoOid)
    }
}
